import React from "react";
import { Route, Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

// A wrapper for <Route> that redirects to the dashboard
// screen if you're already authenticated.
function PublicRoute({ component: Component, loggedIn, location, ...rest }) {
  return (
    <Route {...rest}>
      {!loggedIn ? (
        ({ match }) => (
          <CSSTransition
            key={location.key}
            in={match != null}
            classNames="page"
            timeout={1000}
            unmountOnExit
            appear
          >
            <div className="page">
              <Component {...rest} />
            </div>
          </CSSTransition>
        )
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )}
    </Route>
  );
}

export default PublicRoute;
