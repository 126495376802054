/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function SelectSKUQuantity(props) {
  const {
    element,
    state,
    stateSetter,
    setImageReference,
    imageReference,
  } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let fields = state.fields;
    fields[name] = value;
    stateSetter({ fields: fields });
  };

  return (
    <Form.Group as={Row} controlId={element.name}>
      <Form.Label column sm="4">
        {element.label}
      </Form.Label>
      <Col sm="5">
        <Form.Control
          as="select"
          name={element.name + "[SKU]"}
          autoComplete="off"
          aria-label={element.label}
          required
          onChange={(e) => {
            if (!state.fields[element.name + "[QUANTITY]"]) {
              state.fields[element.name + "[QUANTITY]"] = "1";
            }
            handleInputChange(e);
          }}
          value={state.fields[element.name + "[SKU]"] || element.value || ""}
        >
          <option value="" disabled></option>
          {element.options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Col>
      <Col sm="3">
        <Form.Control
          type="number"
          name={element.name + "[QUANTITY]"}
          autoComplete="off"
          aria-label={element.label}
          required
          min="0"
          placeholder="0"
          onChange={handleInputChange}
          value={state.fields[element.name + "[QUANTITY]"] || ""}
          onFocus={() => {
            imageReference && setImageReference(imageReference);
          }}
        />
      </Col>
    </Form.Group>
  );
}

export default SelectSKUQuantity;
