/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import { Translate } from "react-redux-i18nify";
import Card from "react-bootstrap/Card";
import CartItem from "./CartItem";
import CartIcon from "../../../icons/shopping-cart.svg";

import { displayPrice } from "../../../helpers/format";
import { applyDiscount } from "../../../helpers/discounts";

function Cart(props) {
  const { cart, selectStep, cartRemoveItem } = props;

  return (
    <Card border="primary" style={{ maxWidth: 220, borderWidth: 2 }}>
      <Card.Header className="text-center border-primary">
        <div style={{ position: "absolute" }}>
          <span style={{ position: "absolute", zIndex: 1000 }}>
            <img src={CartIcon} width={20} alt="" />
          </span>
        </div>
        <Translate value="Cart" />
      </Card.Header>
      <Card.Body
        className="px-0 pt-3 pb-0"
        style={{ maxHeight: 400, overflowY: "auto" }}
      >
        {Object.keys(cart).map((sku, i) => (
          <CartItem
            key={sku}
            data={cart[sku]}
            selectStep={selectStep}
            cartRemoveItem={cartRemoveItem}
          />
        ))}
      </Card.Body>
      <Card.Footer className="border-primary">
        <span style={{ fontSize: "13px" }}>
          <Translate value="Total" />: U$D{" "}
          {displayPrice(
            Object.keys(cart).reduce(
              (sum, key) =>
                sum +
                parseFloat(
                  applyDiscount(cart[key].item, cart[key].item.quantity) *
                    cart[key].item.quantity,
                ),
              0,
            ),
          )}{" "}
          + IVA
        </span>
      </Card.Footer>
    </Card>
  );
}

export default Cart;
