/**
 * This file is part of aluwind-cotizador-frontend
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { history } from "../../helpers/history";
import { displayPrice, displayStatus } from "../../helpers/format";
import axios from "axios";
import config from "../../config";

import { Translate } from "react-redux-i18nify";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

function Quotation(props) {
  const [data, setData] = useState({
    quotation: { items: [], status: "loading" },
  });
  let { quotations_id } = useParams();

  useEffect(() => {
    async function getQuotations() {
      const result = await axios.get(
        `${config.API_ENDPOINT}/quotations/${quotations_id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      );
      setData({ quotation: result.data });
    }
    getQuotations();
  }, [quotations_id]);

  const handleRequestQuote = (ev) => {
    console.log("PUSH ORDER", quotations_id);

    async function pushOrder(quotations_id) {
      const result = await axios.post(
        `${config.API_ENDPOINT}/quotations/${quotations_id}/order`,
        null,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      );
      console.log("pushOrder", result);
      history.push("/quotations/" + quotations_id);
    }

    pushOrder(quotations_id);
  };

  return (
    <Container>
      <Row>
        <Col sm="9" className="align-bottom">
          <h3>Cotización web - Baranda Imperia</h3>
        </Col>
        <Col sm="3">
          <div className="d-none d-print-inline-block">
            <img
              src="/images/logo-white2.png"
              alt="Aluwind"
              width="200"
              className="text-right"
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <div>
            <strong>
              <Translate value="Client" />
            </strong>
            :{" "}
            {data.quotation.client ? (
              data.quotation.client
            ) : (
              <span style={{ color: "#bbb" }}>
                <Translate value="Unspecified" />
              </span>
            )}
          </div>
          <div>
            <strong>
              <Translate value="Project" />
            </strong>
            :{" "}
            {data.quotation.project ? (
              data.quotation.project
            ) : (
              <span style={{ color: "#bbb" }}>
                <Translate value="Unspecified" />
              </span>
            )}
          </div>
          <div>
            <strong>
              <Translate value="Status" />
            </strong>{" "}
            : {displayStatus(data.quotation.status)}
            {data.quotation.status === "PENDING_AUTHORIZATION" && (
              <p className="text-danger">
                El pedido está pendiende de aprobación por parte de Aluwind.
              </p>
            )}
          </div>
        </Col>
        <Col className="text-right">
          <h3>
            <Translate value="Quotation No" /> {quotations_id}
          </h3>
          <div>
            <strong>
              <Translate value="Fecha y hora de cotización" />
            </strong>
            :
          </div>
          <div>{data.quotation.date_created}</div>
        </Col>
      </Row>
      <Card className="my-3">
        <Card.Body>
          <strong>
            <Translate value="Notes" />:
          </strong>{" "}
          {data.quotation.notes ? (
            data.quotation.notes
          ) : (
            <span style={{ color: "#bbb" }}>
              <Translate value="Unspecified" />
            </span>
          )}
        </Card.Body>
      </Card>
      <Row className="d-print-none my-3">
        <Col>
          {data.quotation.status === "CREATED" && (
            <Button onClick={handleRequestQuote}>
              <Translate value="Order" />
            </Button>
          )}
        </Col>
        <Col className="text-right">
          <Button variant="secondary" onClick={() => history.push("/")}>
            <Translate value="Exit" />
          </Button>{" "}
          <Button
            onClick={() => {
              window.print();
            }}
          >
            <Translate value="Print" />
          </Button>
        </Col>
      </Row>
      <Table borderless>
        <thead style={{ background: "#ccc", border: "1px solid #000" }}>
          <tr>
            <th>
              <Translate value="Image" />
            </th>
            <th>
              <Translate value="Description" />
            </th>
            <th className="text-center">
              <Translate value="Quantity" />
            </th>
            <th className="text-right">
              <Translate value="Unit Price" />
            </th>
            <th className="text-center">
              <Translate value="Total" />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.quotation.items.map((item) =>
            item.system ? (
              <tr
                key={item.system.id}
                style={{ borderBottom: "1px solid #ccc" }}
              >
                <td>
                  <Image
                    src={item.system.image}
                    alt={item.system.name}
                    width="48"
                  />
                </td>
                <td>
                  <div>
                    <strong>
                      {item.system.name} {item.system.description}.
                    </strong>
                  </div>
                  {item.system.notes && <div>{item.system.notes}.</div>}
                  <ul className="list-metadata">
                    {item.system.visible_length && (
                      <li>
                        <Translate value="Length" /> {item.system.length} mts
                      </li>
                    )}

                    {item.system.visible_metadata &&
                      item.metadata &&
                      Object.keys(item.metadata).map((j, k) => (
                        <li>{item.metadata[j]}</li>
                      ))}
                  </ul>
                </td>
                <td className="text-center">
                  <h4>{item.quantity}</h4>
                  {item.system.visible_length && item.system.length && (
                    <p class="small">
                      {item.system.length * item.quantity} mts
                    </p>
                  )}
                </td>
                <td className="text-right">
                  {config.CURRENCY} {displayPrice(item.unit_price)}
                  {item.unit_price !== item.unit_price_base && (
                    <div>
                      <strike>
                        <small>
                          {config.CURRENCY} {displayPrice(item.unit_price_base)}
                        </small>
                      </strike>
                    </div>
                  )}
                </td>
                <td className="text-right">
                  {config.CURRENCY}{" "}
                  {displayPrice(item.quantity * item.unit_price)}
                </td>
              </tr>
            ) : (
              <tr
                key={item.part.sku}
                style={{ borderBottom: "1px solid #ccc" }}
              >
                <td>
                  <Image
                    src={item.part.images.small}
                    alt={item.part.name}
                    width="48"
                  />
                </td>
                <td>
                  <div>
                    <strong>
                      {item.part.name} {item.part.description}
                    </strong>
                  </div>
                  <ul className="list-metadata">
                    {item.metadata &&
                      Object.keys(item.metadata).map((j, k) => (
                        <li>{item.metadata[j]}</li>
                      ))}
                  </ul>
                </td>
                <td className="text-center">
                  <h4>{item.quantity}</h4>
                </td>
                <td className="text-right">
                  {config.CURRENCY} {displayPrice(item.unit_price)}
                  {item.unit_price !== item.unit_price_base && (
                    <div>
                      <strike>
                        <small>
                          {config.CURRENCY} {displayPrice(item.unit_price_base)}
                        </small>
                      </strike>
                    </div>
                  )}
                </td>
                <td className="text-right">
                  {config.CURRENCY}{" "}
                  {displayPrice(item.quantity * item.unit_price)}
                </td>
              </tr>
            ),
          )}
          <tr>
            <td colspan="5"> </td>
          </tr>
          <tr
            variant="primary"
            style={{ background: "#ccc", border: "1px solid #000" }}
          >
            <td colSpan="4">
              <div className="row">
                <div className="col-8">
                  <small>
                    * Los precios están expresados en dólares BNA y no incluyen
                    IVA 21% ni percepciones.
                  </small>
                </div>
                <div className="col-4 text-right">
                  <strong>
                    <Translate value="Total" />
                  </strong>
                </div>
              </div>
            </td>
            <td className="text-right">
              <strong>
                {config.CURRENCY} {displayPrice(data.quotation.total_price)}
              </strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  system: state.system.system,
  loading: state.system.loading,
  error: state.system.error,
});

export default connect(mapStateToProps)(Quotation);
