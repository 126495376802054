/**
 * This file is part of aluwind-cotizador-frontend
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import { displayStatus } from "../../helpers/format";

import { Translate } from "react-redux-i18nify";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

function Orders(props) {
  const [data, setData] = useState({ orders: [] });

  useEffect(() => {
    async function getMyOrders() {
      const result = await axios.get(`${config.API_ENDPOINT}/orders`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setData({ orders: result.data });
    }
    getMyOrders();
  }, []);

  return (
    <Container>
      <h1>
        <Translate value="Orders" />
      </h1>
      {data.orders.length === 0 ? (
        <p>
          <Translate value="You have no orders" />.{" "}
          <Link to="/">
            <Translate value="Click here to generate a new one" />
          </Link>
        </p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>
                <Translate value="Order No" />
              </th>
              <th>
                <Translate value="Quotation No" />
              </th>
              <th>
                <Translate value="Date" />
              </th>
              <th>
                <Translate value="Client" />
              </th>
              <th>
                <Translate value="Project" />
              </th>
              <th>
                <Translate value="Notes" />
              </th>
              <th>
                <Translate value="Status" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.orders.map((order) => (
              <tr key={order.orders_id}>
                <td>{order.orders_id}</td>
                <td>{order.quotations_id}</td>
                <td>{order.date_created.split(" ")[0]}</td>
                <td>{order.quotation.client}</td>
                <td>{order.quotation.project}</td>
                <td>{order.notes}</td>
                <td>{displayStatus(order.status)}</td>
                <td>
                  <Link
                    className="btn btn-sm btn-primary"
                    to={"/orders/" + order.orders_id}
                  >
                    <Translate value="View" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  system: state.system.system,
  loading: state.system.loading,
  error: state.system.error,
});

export default connect(mapStateToProps)(Orders);
