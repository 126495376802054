import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Col";

import { Translate } from "react-redux-i18nify";

function NotFound() {
  return (
    <Container fluid="md">
      <Row className="justify-content-md-center">
        <Col xs lg="4" className="text-center">
          <h1>Oops!</h1>
          <h2>404 Not Found</h2>
          <div className="error-details">
            <Translate value="Sorry, an error has occurred, Requested page not found!" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
