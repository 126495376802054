/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18nify";
import Alert from "react-bootstrap/Alert";

const Alerts = (props) => {
  const { alerts } = props;
  if (alerts !== null && alerts.length > 0) {
    let alertslist;
    alertslist = alerts.map((alert) => {
      return (
        <Alert key={alert.id} variant={alert.variant}>
          <Translate value={alert.message} />
        </Alert>
      );
    });

    return alertslist;
  }
  return null;
};

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStateToProps)(Alerts);
