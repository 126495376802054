/**
 * This file is part of aluwind-cotizador-frontend
 */

import {
  AUTH_VERIFY_SUCCESS,
  AUTH_VERIFY_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
} from "../actions/auth.actions";

const initialState = {
  loggedIn: false,
  user: null,
  token: null,
  expires: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_VERIFY_SUCCESS:
      console.log("AUTH_VERIFY_SUCCESS", action);
      return {
        loggedIn: true,
        user: action.auth.user,
        token: action.auth.token,
        expires: action.auth.expires,
      };
    case AUTH_VERIFY_FAILURE:
      console.log("AUTH_LOGIN_FAILURE", action);
      return {
        loggedIn: false,
        user: action.user,
        token: action.token,
        expires: action.expires,
      };
    case AUTH_LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.auth.user,
        token: action.auth.token,
        expires: action.auth.expires,
      };
    case AUTH_LOGIN_FAILURE:
      console.log("AUTH_LOGIN_FAILURE", action);
      return {
        loggingIn: false,
        error: action.error,
      };
    case AUTH_LOGOUT:
      return {
        loggedIn: false,
      };
    default:
      return state;
  }
}
