/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function SelectColorPrice(props) {
  console.log("SelectColorPrice", props);
  const {
    element,
    state,
    stateSetter,
    component,
    system,
    primarySystemId,
  } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let fields = state.fields;
    fields[name] = value;

    const selected = element.options.reduce((result, option) => {
      if (option.value === value) {
        result = option;
      }
      return result;
    }, null);

    // console.log('Precio seleccionado:', selected.price_key);

    if (component.system.id !== primarySystemId) {
      // Se cambio un color de un elemento que NO es sistema principal
      console.log(`${name} corresponde a un selector de color propio.`);
      let visible = component.system.price.visible;
      component.system.price = component.system[selected.price_key];
      component.system.price.visible = visible;
    } else {
      // Recorrer todos los componentes que sean sistema
      system.steps.forEach((step) => {
        step.items.forEach((stepItem) => {
          stepItem.form.components.forEach((component) => {
            if (component.system) {
              // Verificar que el sistema no tenga si propia metadata de color
              const colorSelectors = component.elements.filter(
                (el) => el.type == "SELECT_COLOR_PRICE",
              );
              if (
                colorSelectors.length > 0 &&
                colorSelectors[0].name !== name
              ) {
                console.log(
                  `${name} corresponde a un selector de color propio. Skipeando`,
                );
                return;
              }
              let visible = component.system.price.visible;
              component.system.price = component.system[selected.price_key];
              component.system.price.visible = visible;
              // console.log('SISTEMA ACTUALIZADO', component.system);
            }
          });
        });
      });
    }

    stateSetter({ fields: fields });
  };

  return (
    <Form.Group as={Row} controlId={element.name}>
      <Form.Label column sm="4">
        {element.label}
      </Form.Label>
      <Col sm="8">
        <Form.Control
          as="select"
          name={element.name}
          autoComplete="off"
          aria-label={element.label}
          required
          onChange={handleInputChange}
          value={state.fields[element.name] || element.value || ""}
        >
          {element.options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Col>
    </Form.Group>
  );
}

export default SelectColorPrice;
