/**
 * This file is part of aluwind-cotizador-frontend
 */

import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18nify";
import alerts from "./alerts.reducer";
import auth from "./auth.reducer";
import systems from "./systems.reducer";
import system from "./system.reducer";
import registration from "./registration.reducer";
import forgotPassword from "./forgotPassword.reducer";

const rootReducer = combineReducers({
  i18n: i18nReducer,
  alerts,
  auth,
  forgotPassword,
  registration,
  systems,
  system,
});

export default rootReducer;
