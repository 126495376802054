/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { Translate } from "react-redux-i18nify";

export const displayPrice = (price) => {
  return parseFloat(price).toFixed(2);
};

export const displayStatus = (status) => {
  return (
    <span className="badge badge-secondary">
      <Translate value={status} />
    </span>
  );
};
