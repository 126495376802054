/**
 * This file is part of aluwind-cotizador-frontend
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { history } from "../../helpers/history";
import axios from "axios";
import config from "../../config";
import { displayStatus } from "../../helpers/format";

import { Translate } from "react-redux-i18nify";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Order(props) {
  const [data, setData] = useState({
    order: { status: "", quotation: { items: [] }, items: [] },
  });
  let { orders_id } = useParams();

  console.log("orders_id", orders_id);
  useEffect(() => {
    async function getMyOrders() {
      const result = await axios.get(
        `${config.API_ENDPOINT}/orders/${orders_id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      );
      console.log("getMyOrders", result);
      setData({ order: result.data });
    }
    getMyOrders();
  }, [orders_id]);

  const handleViewQuote = (ev) => {
    history.push(`/quotations/${data.order.quotation.quotations_id}`);
  };

  return (
    <Container>
      <Row>
        <Col sm="9" className="align-bottom">
          <h3>Pedido web - Baranda Imperia</h3>
        </Col>
        <Col sm="3">
          <div className="d-none d-print-inline-block">
            <img
              src="/images/logo-white2.png"
              alt="Aluwind"
              width="200"
              className="text-right"
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <div>
            <strong>
              <Translate value="Quotation No" />
            </strong>
            : {data.order.quotation.quotations_id}
          </div>
          <div>
            <strong>
              <Translate value="Client" />
            </strong>
            :{" "}
            {data.order.quotation.client ? (
              data.order.quotation.client
            ) : (
              <span style={{ color: "#bbb" }}>
                <Translate value="Unspecified" />
              </span>
            )}
          </div>
          <div>
            <strong>
              <Translate value="Project" />
            </strong>
            :{" "}
            {data.order.quotation.project ? (
              data.order.quotation.project
            ) : (
              <span style={{ color: "#bbb" }}>
                <Translate value="Unspecified" />
              </span>
            )}
          </div>
          <div>
            <strong>
              <Translate value="Status" />
            </strong>{" "}
            : {displayStatus(data.order.status)}
          </div>
        </Col>
        <Col className="text-right">
          <h3>
            <Translate value="Order No" /> {orders_id}
          </h3>
          <div>
            <strong>
              <Translate value="Fecha y hora de pedido" />
            </strong>
            :
          </div>
          <div>{data.order.date_created}</div>
        </Col>
      </Row>
      <Row className="d-print-none mt-3">
        <Col>
          <Button onClick={handleViewQuote}>
            <Translate value="View quotation" />
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            onClick={() => {
              window.print();
            }}
          >
            <Translate value="Print" />
          </Button>
        </Col>
      </Row>
      <Table borderless className="mt-3">
        <thead style={{ background: "#ccc", border: "1px solid #000" }}>
          <th>
            <Translate value="Image" />
          </th>
          <th>
            <Translate value="Description" />
          </th>
          <th className="text-center">
            <Translate value="Quantity" />
          </th>
        </thead>
        <tbody>
          {data.order.quotation.items.map((item) =>
            item.parts_id ? (
              <tr
                key={item.part.sku}
                style={{ borderBottom: "1px solid #ccc" }}
              >
                <td>
                  <Image
                    src={item.part.images.small}
                    alt={item.part.name}
                    width="48"
                  />
                </td>
                <td>
                  <div>
                    <strong>
                      {item.part.name} {item.part.description}
                    </strong>
                  </div>
                  <ul className="list-metadata">
                    {item.metadata &&
                      Object.keys(item.metadata).map((j, k) => (
                        <li>{item.metadata[j]}</li>
                      ))}
                  </ul>
                </td>
                <td className="text-center">
                  <h4>{item.quantity}</h4>
                </td>
              </tr>
            ) : (
              <tr
                key={item.system.id}
                style={{ borderBottom: "1px solid #ccc" }}
              >
                <td>
                  <Image
                    src={item.system.image}
                    alt={item.system.name}
                    width="48"
                  />
                </td>
                <td>
                  <div>
                    <strong>
                      {item.system.name} {item.system.description}.
                    </strong>
                  </div>
                  {item.system.notes && <div>{item.system.notes}.</div>}
                  <div>
                    <ul className="list-metadata">
                      {item.system.visible_length && (
                        <li>
                          <Translate value="Length" /> {item.system.length} mts
                        </li>
                      )}
                      {item.system.visible_metadata &&
                        item.metadata &&
                        Object.keys(item.metadata).map((j, k) => (
                          <li>{item.metadata[j]}</li>
                        ))}
                    </ul>
                  </div>
                </td>
                <td className="text-center">
                  <h4>{item.quantity}</h4>
                  {item.system.visible_length && (
                    <p class="small">
                      {item.system.length * item.quantity} mts
                    </p>
                  )}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </Table>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  system: state.system.system,
  loading: state.system.loading,
  error: state.system.error,
});

export default connect(mapStateToProps)(Order);
