/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function SelectMetadata(props) {
  const { element, state } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value !== state.fields[name]) {
      props.selectSystem(parseInt(value));
    }
  };

  return (
    <Form.Group as={Row} controlId={element.name}>
      <Form.Label column sm="4">
        {element.label}
      </Form.Label>
      <Col sm="8">
        <Form.Control
          as="select"
          name={element.name}
          autoComplete="off"
          aria-label={element.label}
          required
          onChange={handleInputChange}
          value={state.fields[element.name] || element.value || ""}
        >
          {element.options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Col>
    </Form.Group>
  );
}

export default SelectMetadata;
