/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { connect } from "react-redux";

import { Translate } from "react-redux-i18nify";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Media from "react-bootstrap/Media";
import Row from "react-bootstrap/Row";
import Carousel from "react-bootstrap/Carousel";

import Steps from "./Steps";
import { shouldRenderStep } from "./Steps";
import Cart from "./Cart/Cart";
import CartSummary from "./Cart/CartSummary";
import FormComponent from "./FormComponent";

import { fetchSystem } from "../../redux/actions/system.actions";
import Loading from "../Layout/Loading";

class Quoter extends React.Component {
  initialFields() {
    return {
      "1_1_GLASS": "10mm",
      "1_1_COLOR": "Crudo",
      "1_1_VARILLA": "VR16",
    };
  }

  constructor(props) {
    console.log("Quoter", props);
    super(props);

    this.state = {
      cart: {},
      fields: this.initialFields(),
      selectedStep: 0,
      selectedStepItem: 0,
      showCart: false,
      loaded: false,
    };

    this.selectSystem = this.selectSystem.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectStep = this.selectStep.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.cartAddItem = this.cartAddItem.bind(this);
    this.cartRemoveItem = this.cartRemoveItem.bind(this);
    this.stateSetter = this.stateSetter.bind(this);
    this.initialFields = this.initialFields.bind(this);
    this.imageRef = React.createRef();
    this.setImageReference = this.setImageReference.bind(this);
    this.primarySystemId = props.computedMatch.params.systems_id;
  }

  componentDidMount() {
    const { systems_id } = this.props.computedMatch.params;
    this.selectSystem(systems_id);
  }

  selectSystem(system) {
    // this.setState({
    //   cart: {},
    //   fields: this.initialFields(),
    // });
    this.props.dispatch(fetchSystem(system, this.stateSetter));
    // Le avisamos al navegador que tenemos cambios sin guardar
    window.onbeforeunload = () =>
      this.state.selectedSystem !== null ? "" : null;
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    let fields = this.state.fields;
    fields[name] = value;
    this.stateSetter({ fields: fields });
  }

  prevStep() {
    const { selectedStep, selectedStepItem } = this.state;
    const steps = this.props.system.steps;

    let newStep = selectedStep;
    let newStepItem = selectedStepItem;

    do {
      if (newStepItem > 0) {
        newStepItem -= 1;
      } else if (newStep > 0) {
        newStep -= 1;
        newStepItem = steps[newStep].items.length - 1;
      }
    } while (!shouldRenderStep(steps[newStep].items[newStepItem], this.state));

    return this.selectStep(newStep, newStepItem);
  }

  nextStep() {
    const { selectedStep, selectedStepItem } = this.state;
    const steps = this.props.system.steps;

    let newStep = selectedStep;
    let newStepItem = selectedStepItem;

    do {
      if (steps[newStep].items.length > newStepItem + 1) {
        newStepItem += 1;
      } else if (steps.length > newStep + 1) {
        newStep += 1;
        newStepItem = 0;
      }
    } while (!shouldRenderStep(steps[newStep].items[newStepItem], this.state));

    return this.selectStep(newStep, newStepItem);
  }

  selectStep(step, item) {
    this.setState({
      selectedStep: step,
      selectedStepItem: item,
    });
  }

  cartAddItem(name, item, step) {
    var newCart = this.state.cart;

    if (item.quantity === 0 || Number.isNaN(item.quantity)) {
      if (newCart[name]) {
        delete newCart[name];
        this.setState({ cart: newCart });
      }
      return;
    }

    if (item.quantity > 0) {
      newCart[name] = {
        item: item,
        step: step,
      };
      this.setState({ cart: newCart });
    }
  }

  cartRemoveItem(ref) {
    var newFields = this.state.fields;
    var newCart = this.state.cart;

    newFields[ref] = 0;
    Object.keys(newCart).forEach((k, v) => {
      let item = newCart[k];
      if (item.ref === ref) {
        delete newCart[k];
      }
    });

    this.stateSetter({
      fields: newFields,
      cart: newCart,
    });
  }

  rebuildCart() {
    const { fields } = this.state;
    const { system } = this.props;

    for (
      var selectedStep = 0;
      selectedStep < system.steps.length;
      selectedStep++
    ) {
      for (
        var selectedStepItem = 0;
        selectedStepItem < system.steps[selectedStep].items.length;
        selectedStepItem++
      ) {
        for (
          var i = 0;
          i <
          system.steps[selectedStep].items[selectedStepItem].form.components
            .length;
          i++
        ) {
          let component =
            system.steps[selectedStep].items[selectedStepItem].form.components[
              i
            ];

          var key = `${selectedStep + 1}_${selectedStepItem + 1}_${i}`;
          var item = {};
          var update = false;

          if (Object.prototype.hasOwnProperty.call(this.state.cart, key)) {
            item = this.state.cart[key].item;
          }

          if (component.system) {
            item = {
              type: "SYSTEM",
              sku: component.system.id,
              name: component.system.name,
              image: component.system.image,
              description: component.system.description,
              notes: component.system.notes,
              price: {
                currency: component.system.price.currency,
                value: component.system.price.value * component.system.length,
              },
              length: component.system.length,
              visible_length: component.system.visible_length,
              visible_metadata: component.system.visible_metadata,
              discounts_enabled: component.system.discounts_enabled,
              ref: null,
            };
            for (var j = 0; j < component.elements.length; j++) {
              let element = component.elements[j];

              if (element.type === "QUANTITY") {
                if (item.quantity !== fields[element.name]) {
                  item.quantity = fields[element.name]
                    ? parseInt(fields[element.name])
                    : 0;
                  item.ref = element.name;
                  update = true;
                }
                continue;
              }
              if (element.type === "SYSTEM_QUANTITY") {
                item.quantity = fields[element.name + "_UNIDADES"]
                  ? parseInt(fields[element.name + "_UNIDADES"])
                  : 0;
                item.ref = element.name + "_UNIDADES";
                update = true;
                continue;
              }
              if (
                element.type === "SELECT_METADATA" ||
                element.type === "SELECT_COLOR_PRICE" ||
                element.type === "SELECT_SKU"
              ) {
                if (!Object.prototype.hasOwnProperty.call(item, "metadata")) {
                  item.metadata = {};
                }
                if (item.metadata[element.name] !== fields[element.name]) {
                  item.metadata[element.name] = fields[element.name];
                  update = true;
                }
                continue;
              }
            }
          } else if (component.item) {
            item = {
              type: "PART",
              sku: component.item.sku,
              name: component.item.name,
              image: component.item.images.small,
              description: component.item.description,
              price: component.item.price,
              ref: null,
            };
            component.elements.forEach((element, index) => {
              if (element.type === "QUANTITY") {
                item.quantity = parseInt(fields[element.name]);
                item.ref = element.name;
                update = true;
                return;
              }
              if (
                element.type === "SELECT_METADATA" ||
                element.type === "SELECT_COLOR_PRICE" ||
                element.type === "SELECT_SKU"
              ) {
                if (!Object.prototype.hasOwnProperty.call(item, "metadata")) {
                  item.metadata = {};
                }
                item.metadata[element.name] = fields[element.name];
                return;
              }
            });
          } else {
            item = {
              type: "PART",
            };
            component.elements.forEach((element, index) => {
              if (element.type === "SELECT_SKU") {
                if (item.sku !== fields[element.name]) {
                  this.cartAddItem(key, { quantity: 0 }, [0, 0]);
                  item.sku = fields[element.name];
                  item.name = element.label + " " + item.sku;
                  item.image = "/images/products/130x130/" + item.sku + ".png";
                  item.quantity = 1;
                  item.ref = element.name;
                  update = true;
                }
                return;
              }
              if (element.type === "SELECT_SKU_QUANTITY") {
                if (item.sku !== fields[element.name + "[SKU]"]) {
                  this.cartAddItem(key, { quantity: 0 }, [0, 0]);
                  item.sku = fields[element.name + "[SKU]"];
                  item.name = element.label + " " + item.sku;
                  item.image = "/images/products/130x130/" + item.sku + ".png";
                  item.ref = element.name + "[QUANTITY]";
                  update = true;
                }
                let cantidad = parseInt(fields[element.name + "[QUANTITY]"]);
                if (cantidad !== "NaN" && cantidad !== item.quantity) {
                  item.quantity = parseInt(cantidad);
                  update = true;
                }
                return;
              }
            });
          }

          if (update) {
            // console.log('UPDATEANDO ITEM DEL CARRO', item);
            this.cartAddItem(key, item, [selectedStep, selectedStepItem]);
          }
        }
      }
    }
  }

  stateSetter(newState, rebuild = true) {
    this.setState(newState);
    if (rebuild) this.rebuildCart();
  }

  setImageReference(src) {
    if (!this.imageRef.current) return;
    this.imageRef.current.src = src;
  }

  render() {
    const { error, loading, loaded, system } = this.props;
    const { selectedStep, selectedStepItem, cart } = this.state;

    // Ocurrio un error al cargar?
    if (error) {
      return (
        <Alert variant="danger">
          <Alert.Heading>Error!</Alert.Heading>
          <p>
            <Translate value={error.message} />
          </p>
        </Alert>
      );
    }

    // Estamos cargando?
    if (loading || !loaded) {
      return <Loading />;
    }

    // Estamos mostrando el resumen del carrito?
    if (this.state.showCart) {
      return (
        <CartSummary
          cart={cart}
          hide={() => {
            this.setState({ showCart: false });
          }}
          systems_id={this.props.computedMatch.params.systems_id}
        />
      );
    }

    // Dibujamos el cotizador
    console.log("Dibujando cotizador...");

    const data = system.steps[selectedStep].items[selectedStepItem];
    const lastStep = system.steps.length - 1;
    const lastStepItem = system.steps[lastStep].items.length - 1;
    const images = data.reference.image.split(",");

    return (
      <Container>
        <Steps
          steps={system.steps}
          selectedStep={selectedStep}
          selectedStepItem={selectedStepItem}
          selectStep={this.selectStep}
          data={data}
          state={this.state}
        />
        <CardDeck>
          <Cart
            cart={this.state.cart}
            selectStep={this.selectStep}
            cartRemoveItem={this.cartRemoveItem}
          />
          <Card style={{ maxWidth: 350 }}>
            <Card.Header>
              <Translate value="Reference" />
              {data.reference.link && (
                <a
                  className="btn btn-sm badge btn-primary float-right"
                  href={data.reference.link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.reference.link.label}
                </a>
              )}
            </Card.Header>
            {images.length == 1 && (
              <Card.Img src={data.reference.image} ref={this.imageRef} />
            )}

            {images.length > 1 && (
              <Carousel data-bs-theme="dark">
                {images.map((image, index) => (
                  <Carousel.Item key={index}>
                    <Card.Img src={image} />
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </Card>
          <Card className="border-0 p-0">
            <Card.Body
              className="p-0"
              style={{ height: 500, overflowY: "auto" }}
            >
              {data.item && (
                <>
                  <Media>
                    <img
                      width={130}
                      height={130}
                      className="mr-3"
                      src={data.item.images.small}
                      alt={data.item.name}
                    />
                    <Media.Body>
                      <h5>{data.item.name}</h5>
                      <p>{data.item.description}</p>
                      <p>
                        <small>
                          <Translate value="Price" />: {data.item.price}
                        </small>
                      </p>
                    </Media.Body>
                  </Media>
                  <hr />
                </>
              )}
              {data.form.components.map((component, index) => (
                <FormComponent
                  component={component}
                  componentIndex={index}
                  key={index}
                  state={this.state}
                  addItemFromComponent={this.addItemFromComponent}
                  handleInputChange={this.handleInputChange}
                  stateSetter={this.stateSetter}
                  selectSystem={this.selectSystem}
                  data={data}
                  system={system}
                  setImageReference={this.setImageReference}
                  primarySystemId={this.primarySystemId}
                />
              ))}
            </Card.Body>
          </Card>
        </CardDeck>
        <div className="pt-3">
          <Row>
            <Col style={{ maxWidth: 235, paddingRight: 0 }}>
              {Object.keys(this.state.cart).length > 0 && (
                <Button
                  variant="primary"
                  onClick={() => {
                    this.setState({ showCart: true });
                  }}
                  style={{ width: "100%" }}
                >
                  <Translate value="Confirm" />
                </Button>
              )}
            </Col>
            <Col style={{ paddingLeft: 30 }}>
              <Button
                variant="secondary"
                onClick={this.prevStep}
                disabled={selectedStep === 0 && selectedStepItem === 0}
                className={
                  selectedStep === 0 && selectedStepItem === 0 ? "d-none" : ""
                }
              >
                <Translate value="Back" />
              </Button>
            </Col>
            <Col className="text-right">
              <Button
                variant="secondary"
                onClick={this.nextStep}
                disabled={
                  selectedStep === lastStep && selectedStepItem === lastStepItem
                }
                className={
                  selectedStep === lastStep && selectedStepItem === lastStepItem
                    ? "d-none"
                    : ""
                }
              >
                <Translate value="Next" />
              </Button>
            </Col>
          </Row>
        </div>
        {/*<pre>{JSON.stringify(this.state, null, 4)}</pre>
      <pre>{JSON.stringify(this.props, null, 4)}</pre>*/}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  system: state.system.system,
  loading: state.system.loading,
  error: state.system.error,
  loaded: state.system.loaded,
});

export default connect(mapStateToProps)(Quoter);
