const es = {
  User: "Usuario",
  Password: "Contraseña",
  Login: "Iniciar sesión",
  Logout: "Cerrar sesión",
  "I forgot my password": "Olvide mi contraseña",
  "Sign up": "Registrarme",
  "Reset my password": "Restablecer mi contraseña",
  "Forgot password?": "¿Se te olvidó tu contraseña?",
  "Sorry, an error has occurred, Requested page not found!":
    "Lo sentimos, se ha producido un error. ¡No se encontró la página solicitada!",
  "Guardrail quoter": "Cotizador de barandas",
  "Wrong username or password": "Nombre de usuario o contraseña incorrectos",
  ERROR_TRY_AGAIN: "Algo salió mal. Por favor, inténtelo de nuevo más tarde",
  "Passwords don't match": "Las contraseñas no coinciden",
  "Choose your system": "Elija su sistema",
  Cart: "Carrito",
  Reference: "Referencia",
  Back: "Volver",
  Next: "Siguiente",
  loading: "Cargando...",
  Name: "Nombre",
  Description: "Descripción",
  "First name": "Nombre",
  "Last name": "Apellido",
  "Confirm password": "Confirmar contraseña",
  "Finish order": "Finalizar pedido",
  Quantity: "Cantidad",
  Add: "Agregar",
  Length: "Longitud",
  Units: "Unidades",
  Bars: "Tiras",
  Finish: "Finalizar",
  Price: "Precio",
  Quotations: "Cotizaciones",
  Orders: "Pedidos",
  "Quotation No": "Cotización N°",
  Date: "Fecha",
  Client: "Cliente",
  Project: "Obra",
  "Total price": "Precio total",
  View: "Ver",
  Cancel: "Cancelar",
  Copy: "Copiar",
  Print: "Imprimir",
  "Unit Price": "Precio Un.",
  "Order No": "Pedido N°",
  "Request quote": "Cotizar",
  "View quotation": "Ver cotización",
  Status: "Estado",
  Order: "Pedir",
  "You have no quotes": "No tienes cotizaciones",
  "You have no orders": "No tienes pedidos",
  "Click here to generate a new one": "Haga click aqui para generar una nueva",
  Notes: "Observaciones",
  "Customer info": "Datos de cotizacion / Pedido",
  "Starting from": "Desde",
  PENDING: "En producción",
  PENDING_AUTHORIZATION: "Pedido – En revisión",
  CANCELLED: "Cancelado",
  REQUESTED: "Pedido – Confirmado",
  CREATED: "Cotizado – Sin pedido",
  Unspecified: "Sin especificar",
  Home: "Inicio",
  "Contact phone": "Teléfono de contacto",
  "Company name": "Razón social",
  "An email was sent to your inbox":
    "Usuario registrado exitosamente. Durante las próximas 6 hs hábiles podrá acceder a la  plataforma. Recibirá un correo electrónico en cuanto se habilite el acceso.",
  "email used by another account":
    "El correo electrónico ingresado está siendo utilizado por otra cuenta",
  "username already taken": "El CUIT está siendo utilizado por otra cuenta",
  "Disabled user": "Usuario deshabilitado",
  Exit: "Salir",
  Confirm: "Confirmar",
  Image: "Imagen",
  Modify: "Modificar",
  Confirmation: "Confirmación",
};

export default es;
