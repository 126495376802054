/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const evaluator = (func, vars) => {
  return new Function("v", "with (v) { return (" + func + ")}")(vars);
};

function shouldRenderStep(stepData, state) {
  if (stepData.form) {
    for (const componentIndex in stepData.form.components) {
      const component = stepData.form.components[componentIndex];
      if (component.condition) {
        if (evaluator(component.condition, state)) {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  return false;
}

class Steps extends React.Component {
  constructor(props) {
    super(props);

    this.state = props.state;
  }

  render() {
    const { steps, selectedStep, selectedStepItem, selectStep } = this.props;
    return (
      <Row className="steps-group">
        {steps.map((step, stepIndex) => (
          <Col key={stepIndex}>
            <span
              className={
                "step-name" + (stepIndex === selectedStep ? " active" : "")
              }
            >
              {step.name}
            </span>
            <br />
            <div className="steps">
              {step.items.map(
                (item, stepItemIndex) =>
                  shouldRenderStep(item, this.state) && (
                    <span
                      key={stepItemIndex}
                      className={
                        "step-circle" +
                        (stepIndex === selectedStep &&
                        stepItemIndex === selectedStepItem
                          ? " active"
                          : "")
                      }
                      onClick={() => {
                        selectStep(stepIndex, stepItemIndex);
                      }}
                    >
                      &#9679;
                    </span>
                  ),
              )}
            </div>
          </Col>
        ))}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  systems: state.systems.items,
  loading: state.systems.loading,
  error: state.systems.error,
});

export default connect(mapStateToProps)(Steps);
export { shouldRenderStep };
