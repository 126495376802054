/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function Quantity(props) {
  const {
    element,
    state,
    stateSetter,
    imageReference,
    setImageReference,
  } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let fields = state.fields;
    fields[name] = value;
    stateSetter({ fields: fields });
  };

  return (
    <Form.Group as={Row} controlId={element.name}>
      <Form.Label column sm="4">
        {element.label}
      </Form.Label>
      <Col sm="8">
        <Form.Control
          type="number"
          min="0"
          name={element.name}
          placeholder="0"
          autoComplete="off"
          aria-label={element.label}
          onChange={handleInputChange}
          value={state.fields[element.name] || element.value}
          required
          onFocus={() => {
            imageReference && setImageReference(imageReference);
          }}
        />
      </Col>
    </Form.Group>
  );
}

export default Quantity;
