/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import { connect } from "react-redux";
import { Translate } from "react-redux-i18nify";
import { NavLink } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { authActions } from "../../redux/actions/auth.actions";

class Cabecera extends React.Component {
  render() {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    return (
      <Navbar
        bg="black"
        variant="dark"
        style={{ backgroundColor: "#000 !important" }}
      >
        <Row className="w-100">
          <Col>
            <Navbar.Brand href="/">
              <img
                alt="Aluwind"
                src="/images/logo-white.png"
                width="155"
                height="41"
                className="d-inline-block align-top"
              />{" "}
            </Navbar.Brand>
            <Navbar.Toggle />
          </Col>
          <Col xs={6} className="my-auto text-center">
            <h1 className="h3 text-white">
              <Translate value="Guardrail quoter" />
            </h1>
          </Col>
          <Col>
            <Navbar.Collapse className="justify-content-end">
              {user ? (
                <>
                  <NavLink to="/" className="nav-link" activeClassName="active">
                    <Translate value="Home" />
                  </NavLink>
                  <NavLink
                    to="/quotations"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <Translate value="Quotations" />
                  </NavLink>
                  <NavLink
                    to="/orders"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <Translate value="Orders" />
                  </NavLink>
                  <Nav className="ml-auto">
                    <NavDropdown
                      title={
                        <Image
                          src={user.avatar}
                          roundedCircle
                          width="32"
                          height="32"
                        />
                      }
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item>
                        {user.firstname} {user.lastname}
                        <br />
                        {user.username}
                        <br />
                        {user.email}
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          this.props.dispatch(authActions.logout());
                        }}
                      >
                        <Translate value="Logout" />
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </>
              ) : (
                <Nav className="ml-auto">
                  <Nav.Link to="/login">
                    <Translate value="Login" />
                  </Nav.Link>
                </Nav>
              )}
            </Navbar.Collapse>
          </Col>
        </Row>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Cabecera);
