/**
 * This file is part of aluwind-cotizador-frontend
 */

import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
} from "../actions/registration.actions";

const initialState = {
  loading: false,
  error: null,
};

export default function registrationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        loading: true,
      };
    case REGISTER_SUCCESS:
      return {
        loading: false,
      };
    case REGISTER_FAILURE:
      return {
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
