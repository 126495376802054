/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import store from "./redux/store";

import "./scss/aluwind.scss";

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"),
);
