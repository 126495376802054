/**
 * This file is part of aluwind-cotizador-frontend
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { Translate } from "react-redux-i18nify";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import config from "../../config";
import { history } from "../../helpers/history";
import Loading from "../Layout/Loading";
import { displayPrice, displayStatus } from "../../helpers/format";

function Quotations(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ quotations: [] });

  const cancelQuotation = (quotations_id) => {
    async function pushCancel(quotations_id) {
      const result = await axios.delete(
        `${config.API_ENDPOINT}/quotations/${quotations_id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      );
      console.log("pushCancel", result);
      history.push("/quotations");
    }
    pushCancel(quotations_id);
  };

  useEffect(() => {
    async function getQuotations() {
      const result = await axios.get(`${config.API_ENDPOINT}/quotations`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log(result);
      setData({ quotations: result.data });
      setLoading(false);
    }
    getQuotations();
  }, []);

  console.log("xxx", data);

  return (
    <Container>
      <h1>
        <Translate value="Quotations" />
      </h1>
      {loading && (
        <div>
          <div className="loader-wheel"></div>
        </div>
      )}
      {data.quotations.length === 0 ? (
        <p>
          <Translate value="You have no quotes" />.{" "}
          <Link to="/">
            <Translate value="Click here to generate a new one" />
          </Link>
        </p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>
                <Translate value="Quotation No" />
              </th>
              <th>
                <Translate value="Date" />
              </th>
              <th>
                <Translate value="Client" />
              </th>
              <th>
                <Translate value="Project" />
              </th>
              <th>
                <Translate value="Total price" />
              </th>
              <th>
                <Translate value="Status" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.quotations.map((quotation) => (
              <tr key={quotation.quotations_id}>
                <td>{quotation.quotations_id}</td>
                <td>{quotation.date_created.split(" ")[0]}</td>
                <td>{quotation.client}</td>
                <td>{quotation.project}</td>
                <td>
                  {config.CURRENCY} {displayPrice(quotation.total_price)}
                </td>
                <td>{displayStatus(quotation.status)}</td>
                <td>
                  <Link
                    className="btn btn-sm btn-primary"
                    to={"/quotations/" + quotation.quotations_id}
                  >
                    <Translate value="View" />
                  </Link>{" "}
                  {quotation.status === "CREATED" && (
                    <Button
                      variant="secondary"
                      size="sm"
                      value={quotation.quotations_id}
                      onClick={(e) => {
                        if (window.confirm("Are you sure?")) {
                          cancelQuotation(e.target.value);
                        }
                      }}
                    >
                      <Translate value="Cancel" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  system: state.system.system,
  loading: state.system.loading,
  error: state.system.error,
});

export default connect(mapStateToProps)(Quotations);
