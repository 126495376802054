/**
 * This file is part of aluwind-cotizador-frontend
 */

import {
  FORGOTPASSWORD_REQUEST,
  FORGOTPASSWORD_SUCCESS,
  FORGOTPASSWORD_FAILURE,
} from "../actions/forgotPassword.actions";

const initialState = {
  success: false,
  error: null,
  loading: false,
};

export default function forgotPasswordReducer(
  state = initialState,
  action = "",
) {
  switch (action.type) {
    case FORGOTPASSWORD_REQUEST:
      return {
        loading: true,
      };
    case FORGOTPASSWORD_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case FORGOTPASSWORD_FAILURE:
      return {
        loading: false,
        error: action.error,
        success: false,
      };
    default:
      return state;
  }
}
