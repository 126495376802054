/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import axios from "axios";
import { Translate } from "react-redux-i18nify";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";

import config from "../../../config";
import { displayPrice } from "../../../helpers/format";
import { history } from "../../../helpers/history";
import { applyDiscount } from "../../../helpers/discounts";
import Loading from "../../Layout/Loading";

class CartSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showPopup: false,
      operation: null,
      fields: {},
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleRequestOrder = this.handleRequestOrder.bind(this);
    this.handleRequestQuote = this.handleRequestQuote.bind(this);
    this.confirmationShow = this.confirmationShow.bind(this);
    this.confirmationClose = this.confirmationClose.bind(this);
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    let fields = this.state.fields;
    fields[name] = value;
    this.setState({ fields: fields });
  }

  handleSubmit(e) {
    console.log("handleSubmit", this.state);
    e.preventDefault();
    switch (this.state.operation) {
      case "quote":
        this.handleRequestQuote();
        break;
      case "order":
        this.handleRequestOrder();
        break;
      default:
        console.warn("Acción invalida");
    }
  }

  handleSubmitClick(e) {
    this.setState({ operation: e.currentTarget.value, showPopup: false });
  }

  handleRequestQuote() {
    const { cart, systems_id } = this.props;
    const { fields } = this.state;

    // Le avisamos al navegador que ya no tenemos cambios sin guardar
    window.onbeforeunload = (s) => null;

    // Iniciamos la carga
    this.setState({ loading: true });

    async function pushQuotation() {
      const result = await axios.post(
        `${config.API_ENDPOINT}/quotations`,
        {
          client: fields.client,
          project: fields.project,
          notes: fields.notes,
          cart,
          systems_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      );
      history.push("/quotations/" + result.data.quotation.quotations_id);
    }

    pushQuotation();
  }

  handleRequestOrder() {
    const { cart, systems_id } = this.props;
    const { fields } = this.state;

    // Le avisamos al navegador que ya no tenemos cambios sin guardar
    window.onbeforeunload = (s) => null;

    // Iniciamos la carga
    this.setState({ loading: true });

    async function pushOrder(quotations_id) {
      await axios.post(
        `${config.API_ENDPOINT}/quotations/${quotations_id}/order`,
        null,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      );
      history.push("/quotations/" + quotations_id);
    }

    async function pushQuotation() {
      const result = await axios.post(
        `${config.API_ENDPOINT}/quotations`,
        {
          client: fields.client,
          project: fields.project,
          notes: fields.notes,
          cart,
          systems_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      );
      pushOrder(result.data.quotation.quotations_id);
    }

    pushQuotation();
  }

  confirmationShow(e) {
    console.log("confirmationShow", e.currentTarget.value);
    this.setState({ operation: e.currentTarget.value, showPopup: true });
  }
  confirmationClose() {
    this.setState({ showPopup: false });
  }

  render() {
    const { cart, hide } = this.props;
    const { loading, showPopup, operation } = this.state;
    return (
      <Container>
        <Card border="primary">
          <Card.Header className="bg-primary text-light">
            <Row>
              <Col>
                <Button
                  className="d-print-none"
                  onClick={hide}
                  style={{ border: "1px solid #fff" }}
                >
                  <Translate value="Back" />
                </Button>
              </Col>
              <Col className="text-center">
                <h4>
                  <Translate value="Cart" />
                </h4>
              </Col>
              <Col></Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <Translate value="Image" />
                  </th>
                  <th>
                    <Translate value="Description" />
                  </th>
                  <th className="text-center">
                    <Translate value="Quantity" />
                  </th>
                  <th className="text-right">
                    <Translate value="Unit Price" />
                  </th>
                  <th className="text-right">
                    <Translate value="Total" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(cart).map((key, i) => {
                  const priceWithoutDiscount = cart[key].item.price.value;
                  const price = applyDiscount(
                    cart[key].item,
                    cart[key].item.quantity,
                  );

                  return (
                    <tr key={cart[key].item.sku}>
                      <td>
                        <Image
                          src={cart[key].item.image}
                          alt={cart[key].item.name}
                          width="48"
                        />
                      </td>
                      <td>
                        <div>
                          <strong>
                            {cart[key].item.name} {cart[key].item.description}
                          </strong>
                        </div>
                        {cart[key].item.notes && (
                          <div>{cart[key].item.notes}</div>
                        )}
                        <ul className="list-metadata">
                          {cart[key].item.visible_length && (
                            <li>
                              <Translate value="Length" />{" "}
                              {cart[key].item.length} mts
                            </li>
                          )}
                          {cart[key].item.visible_metadata &&
                            cart[key].item.metadata &&
                            Object.keys(cart[key].item.metadata).map((j, k) => (
                              <li>{cart[key].item.metadata[j]}</li>
                            ))}
                        </ul>
                      </td>
                      <td className="text-center">
                        <h4>{cart[key].item.quantity}</h4>
                        {cart[key].item.type === "SYSTEM" &&
                          cart[key].item.visible_length && (
                            <p className="small">
                              {cart[key].item.length * cart[key].item.quantity}{" "}
                              mts
                            </p>
                          )}
                      </td>
                      <td className="text-right">
                        {cart[key].item.price.currency} {displayPrice(price)}
                        {price !== priceWithoutDiscount && (
                          <div>
                            <small>
                              <strike>
                                {cart[key].item.price.currency}{" "}
                                {displayPrice(priceWithoutDiscount)}
                              </strike>
                            </small>
                          </div>
                        )}
                      </td>
                      <td className="text-right">
                        {cart[key].item.price.currency}{" "}
                        {displayPrice(cart[key].item.quantity * price)}
                      </td>
                    </tr>
                  );
                })}
                <tr variant="primary">
                  <td colSpan="4">
                    <strong>
                      <Translate value="Total" />
                    </strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {config.CURRENCY}{" "}
                      {displayPrice(
                        Object.keys(cart).reduce(
                          (sum, key) =>
                            sum +
                            parseFloat(
                              applyDiscount(
                                cart[key].item,
                                cart[key].item.quantity,
                              ) * cart[key].item.quantity,
                            ),
                          0,
                        ),
                      )}{" "}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div>
              * Los precios están expresados en dólares BNA y no incluyen IVA
              21% ni percepciones.
            </div>
          </Card.Body>
          <Card.Footer>
            <h5>
              <Translate value="Customer info" />
            </h5>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="qz.a1">
                <Form.Control
                  name="client"
                  type="text"
                  placeholder="Cliente"
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="qz.a2">
                <Form.Control
                  name="project"
                  type="text"
                  placeholder="Obra"
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="qz.a3">
                <Form.Control
                  name="notes"
                  as="textarea"
                  rows="3"
                  placeholder="Observaciones"
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Row className="d-print-none">
                <Col>
                  <Button
                    variant="primary"
                    value="quote"
                    onClick={this.confirmationShow}
                  >
                    <Translate value="Request quote" />
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    value="order"
                    onClick={this.confirmationShow}
                  >
                    <Translate value="Order" />
                  </Button>
                </Col>
                <Col className="text-right">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    <Translate value="Print" />
                  </Button>
                </Col>
              </Row>

              {showPopup && (
                <div
                  className="fade modal show"
                  style={{ display: "block", background: "rgba(0,0,0,0.5)" }}
                  role="dialog"
                >
                  <Modal.Dialog
                    size="lg"
                    onHide={this.confirmationClose}
                    centered
                  >
                    <Modal.Header className="bg-primary text-light">
                      <Modal.Title className="text-uppercase">
                        <Translate value="Confirmation" />
                      </Modal.Title>
                      <button
                        type="button"
                        class="close"
                        onClick={this.confirmationClose}
                      >
                        <span aria-hidden="true">×</span>
                        <span class="sr-only">Close</span>
                      </button>
                    </Modal.Header>

                    <Modal.Body>
                      <Row>
                        <Col className="mx-2">
                          <div className="border border-primary rounded">
                            <div>
                              <img
                                src="/images/refs/512x512/confirm-01.png"
                                alt="Tratamiento"
                                className="w-100"
                              />
                            </div>
                            <div className="text-primary text-center">
                              Tratamiento
                            </div>
                          </div>
                          <div className="text-center p-2">
                            {Object.keys(cart).map((key, i) => {
                              if (cart[key].item.metadata) {
                                return Object.keys(cart[key].item.metadata).map(
                                  (j, k) => {
                                    if (j === "1_1_COLOR")
                                      return cart[key].item.metadata[j];
                                  },
                                );
                              }
                            })}
                          </div>
                        </Col>
                        <Col className="mx-2">
                          <div className="border border-primary rounded">
                            <div>
                              <img
                                src="/images/refs/512x512/confirm-02.png"
                                alt="Espesor de vidrio"
                                className="w-100"
                              />
                            </div>
                            <div className="text-primary text-center">
                              Espesor de vidrio
                            </div>
                          </div>
                          <div className="text-center p-2">
                            {Object.keys(cart).map((key, i) => {
                              if (cart[key].item.metadata) {
                                return Object.keys(cart[key].item.metadata).map(
                                  (j, k) => {
                                    if (j === "1_1_GLASS")
                                      return cart[key].item.metadata[j];
                                  },
                                );
                              }
                            })}
                          </div>
                        </Col>
                        <Col className="mx-2">
                          <div className="border border-primary rounded">
                            <div>
                              <img
                                src="/images/refs/512x512/confirm-03.png"
                                alt="Fijación"
                                className="w-100"
                              />
                            </div>
                            <div className="text-primary text-center">
                              Fijación
                            </div>
                          </div>
                          <div className="text-center p-2">
                            {Object.keys(cart).map((key, i) => {
                              if (cart[key].item.metadata) {
                                return Object.keys(cart[key].item.metadata).map(
                                  (j, k) => {
                                    if (j === "1_1_VARILLA")
                                      return cart[key].item.metadata[j];
                                  },
                                );
                              }
                            })}
                          </div>
                        </Col>
                      </Row>
                    </Modal.Body>

                    <Modal.Footer>
                      <div className="d-flex justify-content-center w-100">
                        <Button
                          variant="secondary"
                          onClick={hide}
                          className="mr-2"
                        >
                          <Translate value="Modify" />
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          data-dismiss="modal"
                        >
                          <Translate value="Confirm" />
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal.Dialog>
                </div>
              )}
            </Form>
          </Card.Footer>
        </Card>
        {/*<pre>{JSON.stringify(this.state, null, 4)}</pre>*/}
        {loading && <Loading />}
      </Container>
    );
  }
}

export default CartSummary;
