/**
 * This file is part of aluwind-cotizador-frontend
 */

import axios from "axios";
import config from "../../config";

export const FETCH_SYSTEM_BEGIN = "FETCH_SYSTEM_BEGIN";
export const FETCH_SYSTEM_SUCCESS = "FETCH_SYSTEM_SUCCESS";
export const FETCH_SYSTEM_FAILURE = "FETCH_SYSTEM_FAILURE";

export function fetchSystem(uid, stateSetter) {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(`${config.API_ENDPOINT}/systems/${uid}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log("fetchSystem", uid, response.data);
        const system = response.data.system;

        // Establecemos los valores por defecto
        let defaultFields = {};
        system.steps.forEach((step) => {
          step.items.forEach((stepItem) => {
            stepItem.form.components.forEach((component) => {
              component.elements.forEach((element) => {
                if (element.value != null && element.value != "") {
                  defaultFields[element.name] = element.value;
                }
              });
            });
          });
        });
        stateSetter({ fields: defaultFields }, false);

        dispatch(success(response.data.system));
        return response.data.system;
      })
      .catch((error) => {
        console.error("fetchSystem", uid, error, error.response);

        if (error.response) {
          if (error.response.status === 403) {
            // Deberiamos forzar a loguear
            console.error("fetchSystem", "Invalid credentials");
            return dispatch(failure({ message: "Invalid credentials" }));
          }
        }

        dispatch(
          failure({ message: "Something went wrong. Please try again later" }),
        );
      });
  };

  function request(system) {
    return { type: FETCH_SYSTEM_BEGIN, payload: { system } };
  }
  function success(system) {
    return { type: FETCH_SYSTEM_SUCCESS, payload: { system } };
  }
  function failure(error) {
    return { type: FETCH_SYSTEM_FAILURE, payload: { error } };
  }
}
