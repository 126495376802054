/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import Card from "react-bootstrap/Card";

import { Translate } from "react-redux-i18nify";
import EditIcon from "../../../icons/edit.svg";
import RemoveIcon from "../../../icons/remove.svg";
import { displayPrice } from "../../../helpers/format";
import { applyDiscount } from "../../../helpers/discounts";

function CartItem(props) {
  const item = props.data.item;
  const quantity = item.quantity;
  const step = props.data.step;
  const selectStep = props.selectStep;
  const cartRemoveItem = props.cartRemoveItem;

  const priceWithoutDiscount = item.price.value * quantity;
  const price = applyDiscount(item, quantity) * quantity;

  return (
    <Card className="mb-3">
      <img
        className="card-img-top"
        src={item.image}
        alt={item.name}
        onError={(e) => {
          e.target.src =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
        }}
      />
      <div className="card-img-overlay p-0 text-right">
        <button
          className="btn btn-secondary bg-white border-light"
          style={{ padding: 0, width: "25px", height: "25px" }}
          onClick={() => {
            selectStep(step[0], step[1]);
          }}
        >
          <img src={EditIcon} alt="Editar" width={10} />
        </button>
        <button
          className="btn btn-secondary bg-white border-light"
          style={{ padding: 0, width: "25px", height: "25px" }}
          onClick={() => {
            cartRemoveItem(item.ref);
          }}
        >
          <img src={RemoveIcon} alt="Eliminar" width={12} />
        </button>
      </div>
      <Card.Footer className="bg-light py-1 px-2">
        <div>
          {item.name}
          {quantity ? " x " + quantity : ""}
        </div>
        <div>
          <small>
            {price === priceWithoutDiscount && (
              <>
                <Translate value="Subtotal" />
                {": "}
              </>
            )}
            {price !== priceWithoutDiscount && (
              <small>
                <strike>
                  {item.price.currency} {displayPrice(priceWithoutDiscount)}
                </strike>
              </small>
            )}
            {item.price.currency} {displayPrice(price)} + IVA
            <br />
          </small>
        </div>
      </Card.Footer>
    </Card>
  );
}

export default CartItem;
