/**
 * This file is part of aluwind-cotizador-frontend
 */

import { translate as t } from "react-i18nify";
import { userService } from "../../services/user.service";
import { alertsActions } from "./alerts.actions";
import { history } from "../../helpers/history";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const registrationActions = {
  register,
};

function register(
  username,
  company,
  firstname,
  lastname,
  email,
  phone,
  password,
) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService
      .register(username, company, firstname, lastname, email, phone, password)
      .then(
        (user) => {
          dispatch(success(user));
          // dispatch(alertsActions.success(t('An email was sent to your inbox')));
          window.alert(t("An email was sent to your inbox"));
          history.push("/login");
        },
        (error) => {
          dispatch(failure(error.toString()));
          // dispatch(alertsActions.error(error.toString()));
          window.alert(t(error.toString()));
        },
      );
  };

  function request(user) {
    return { type: REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: REGISTER_FAILURE, error };
  }
}
