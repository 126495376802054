import React from "react";
import { Route, Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ component: Component, loggedIn, location, ...rest }) {
  if (loggedIn) {
    return (
      <Route {...rest}>
        {({ match }) => (
          <CSSTransition
            key={location.key}
            in={match != null}
            classNames="page"
            timeout={1000}
            unmountOnExit
            appear
          >
            <div className="page">
              <Component {...rest} />
            </div>
          </CSSTransition>
        )}
      </Route>
    );
  }
  return (
    <Route {...rest}>
      <Redirect to={{ pathname: "/login", state: { from: location } }} />
    </Route>
  );
}

export default PrivateRoute;
