/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import { Translate } from "react-redux-i18nify";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function SystemQuantity(props) {
  const {
    component,
    element,
    state,
    stateSetter,
    setImageReference,
    imageReference,
  } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let fields = state.fields;
    fields[name + "_METROS"] = value;
    fields[name + "_UNIDADES"] = Math.ceil(value / component.system.length);
    stateSetter({ fields: fields });
  };

  return (
    <>
      <Form.Group as={Row} controlId={element.name}>
        <Form.Label column sm="4">
          {element.label}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="number"
            name={element.name}
            autoComplete="off"
            aria-label={element.label}
            required
            min="0"
            placeholder="0"
            onChange={handleInputChange}
            value={state.fields[element.name + "_METROS"] || element.value}
            onFocus={() => {
              imageReference && setImageReference(imageReference);
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId={element.name + "_UNIDADES"}>
        <Form.Label column sm="4">
          <Translate value="Bars" />
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="number"
            name={element.name + "_UNIDADES"}
            autoComplete="off"
            placeholder=""
            aria-label={element.label}
            required
            min="0"
            disabled
            value={state.fields[element.name + "_UNIDADES"] || "0"}
          />
        </Col>
      </Form.Group>
    </>
  );
}

export default SystemQuantity;
