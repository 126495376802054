/**
 * This file is part of aluwind-cotizador-frontend
 */

import { userService } from "../../services/user.service";
import { alertsActions } from "./alerts.actions";
import { history } from "../../helpers/history";

export const FORGOTPASSWORD_REQUEST = "FORGOTPASSWORD_REQUEST";
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";
export const FORGOTPASSWORD_FAILURE = "FORGOTPASSWORD_FAILURE";

export const forgotPasswordActions = {
  forgotPassword,
};

function forgotPassword(username) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.forgotPassword(username).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertsActions.success("An email was sent to your inbox"));
        history.push("/login");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertsActions.error(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: FORGOTPASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: FORGOTPASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: FORGOTPASSWORD_FAILURE, error };
  }
}
