/**
 * This file is part of aluwind-cotizador-frontend
 */

import axios from "axios";
import config from "../../config";

export const FETCH_SYSTEMS_BEGIN = "FETCH_SYSTEMS_BEGIN";
export const FETCH_SYSTEMS_SUCCESS = "FETCH_SYSTEMS_SUCCESS";
export const FETCH_SYSTEMS_FAILURE = "FETCH_SYSTEMS_FAILURE";

export const fetchSystemsBegin = () => ({
  type: FETCH_SYSTEMS_BEGIN,
});

export const fetchSystemsSuccess = (systems) => ({
  type: FETCH_SYSTEMS_SUCCESS,
  payload: { systems },
});

export const fetchSystemsFailure = (error) => ({
  type: FETCH_SYSTEMS_FAILURE,
  payload: { error },
});

export function fetchSystems() {
  return (dispatch) => {
    dispatch(fetchSystemsBegin());
    return axios
      .get(`${config.API_ENDPOINT}/systems`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log("fetchSystems", response.data);
        dispatch(fetchSystemsSuccess(response.data.systems));
        return response.data.systems;
      })
      .catch((error) => {
        console.error("fetchSystems", error, error.response);

        if (error.response && error.response.status === 403) {
          // Deberiamos forzar a loguear
          console.error("fetchSystems", "Invalid credentials");
          return dispatch(
            fetchSystemsFailure({ message: "Invalid credentials" }),
          );
        }

        dispatch(
          fetchSystemsFailure({
            message: "Something went wrong. Please try again later",
          }),
        );
      });
  };
}
