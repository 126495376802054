/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

export default function Loading() {
  return (
    <div className="loader">
      <div className="loader-wheel"></div>
    </div>
  );
}
