/**
 * This file is part of aluwind-cotizador-frontend
 */

export const alertsActions = {
  success,
  error,
  clear,
  clearAll,
};

export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_ERROR = "ALERT_ERROR";
export const ALERT_CLEAR = "ALERT_CLEAR";
export const ALERT_CLEAR_ALL = "ALERT_CLEAR_ALL";

function success(message) {
  return { type: ALERT_SUCCESS, message };
}

function error(message) {
  return { type: ALERT_ERROR, message };
}

function clear(id) {
  return { type: ALERT_ERROR, id };
}

function clearAll() {
  return { type: ALERT_CLEAR_ALL };
}
