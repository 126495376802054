/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { connect } from "react-redux";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Col";

import LoginHeader from "./LoginHeader";
import { authActions } from "../../redux/actions/auth.actions";

import { LinkContainer } from "react-router-bootstrap";
import { I18n, Translate } from "react-redux-i18nify";
import { translate as t } from "react-i18nify";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    if (username && password) {
      this.props.dispatch(this.props.login(username, password));
    }
  }

  render() {
    const { loggingIn, error } = this.props;
    const { username, password } = this.state;

    return (
      <Container fluid="md">
        <Row className="justify-content-md-center align-items-center mt-md-5">
          <Col xs lg="4">
            <LoginHeader />
            <Card bg="light">
              <Card.Body>
                <Card.Title className="text-center">
                  <Translate value="Login" />
                </Card.Title>
                <Card>
                  <Card.Body>
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group
                        as={Row}
                        controlId="formUsername"
                        className="items-align-center"
                      >
                        <Col md={{ span: 8, offset: 2 }}>
                          <I18n
                            render={() => (
                              <Form.Control
                                type="text"
                                name="username"
                                autoComplete="username"
                                placeholder={t("User")}
                                aria-label={t("User")}
                                value={username}
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPassword">
                        <Col md={{ span: 8, offset: 2 }}>
                          <I18n
                            render={() => (
                              <Form.Control
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                placeholder={t("Password")}
                                aria-label={t("Password")}
                                value={password}
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>
                      {error && (
                        <Row>
                          <Col md={{ span: 8, offset: 2 }}>
                            <small style={{ color: "red" }}>
                              <Translate value={error} />
                            </small>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={{ span: 8, offset: 2 }}>
                          <Button
                            type="submit"
                            className="float-right"
                            disabled={loggingIn}
                          >
                            <Translate
                              value={loggingIn ? "loading" : "Login"}
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
                <LinkContainer to="/user/forgot-password">
                  <Card.Link href="#" className="float-left mt-2">
                    <Translate value="I forgot my password" />
                  </Card.Link>
                </LinkContainer>
                <LinkContainer to="/user/signup">
                  <Card.Link href="#" className="float-right mt-2">
                    <Translate value="Sign up" />
                  </Card.Link>
                </LinkContainer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.auth.loggingIn,
  error: state.auth.error,
});

const actionCreators = (dispatch) => ({
  login: authActions.login,
  logout: authActions.logout,
  dispatch,
});

export default connect(mapStateToProps, actionCreators)(Login);
