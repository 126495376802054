/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import Image from "react-bootstrap/Image";

import { Translate } from "react-redux-i18nify";

function LoginHeader() {
  return (
    <div className="text-center">
      <style>
        {
          "body { background-color: black; }footer.footer { color: #666 }footer.footer a { color: #666 }"
        }
      </style>
      <Image src="/images/logo-black.png" />
      <h1 className="text-light mt-md-3">
        <Translate value="Guardrail quoter" />
      </h1>
    </div>
  );
}

export default LoginHeader;
