/**
 * This file is part of aluwind-cotizador-frontend
 */

import {
  FETCH_SYSTEM_BEGIN,
  FETCH_SYSTEM_SUCCESS,
  FETCH_SYSTEM_FAILURE,
} from "../actions/system.actions";

const initialState = {
  system: [],
  loading: false,
  loaded: false,
  error: null,
  fields: {},
};

export default function systemReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SYSTEM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        system: action.payload.system,
        fields: {
          coso: true,
        },
      };

    case FETCH_SYSTEM_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
        system: [],
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
