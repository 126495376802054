/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { history } from "../helpers/history";
import { alertsActions } from "../redux/actions/alerts.actions";
import { authActions } from "../redux/actions/auth.actions";
import Alerts from "./Layout/Alerts";
import Cabecera from "./Layout/Cabecera";
import Footer from "./Layout/Footer";
import Quoter from "./Quoter/Quoter";
import Wizzard from "./Quoter/Wizzard";
import Quotations from "./Quotations/Quotations";
import Quotation from "./Quotations/Quotation";
import Orders from "./Orders/Orders";
import Order from "./Orders/Order";
import Login from "./User/Login";
import Logout from "./User/Logout";
import ForgotPassword from "./User/ForgotPassword";
import Register from "./User/Register";
import NotFound from "./UI/NotFound";

import PublicRoute from "./Router/PublicRoute";
import PrivateRoute from "./Router/PrivateRoute";

class App extends React.Component {
  constructor(props) {
    super(props);

    history.listen(() => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  componentDidMount() {
    this.props.dispatch(authActions.verify());
  }

  render() {
    const { loggedIn } = this.props;
    return (
      <>
        <Router history={history}>
          <Cabecera />
          <Alerts />
          <main role="main" className="flex-shrink-0 mb-5 mt-3">
            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={Wizzard}
                loggedIn={loggedIn}
              />
              <PrivateRoute
                exact
                path="/s/:systems_id"
                component={Quoter}
                loggedIn={loggedIn}
              />
              <PrivateRoute
                exact
                path="/quotations"
                component={Quotations}
                loggedIn={loggedIn}
              />
              <PrivateRoute
                exact
                path="/quotations/:quotations_id"
                component={Quotation}
                loggedIn={loggedIn}
              />
              <PrivateRoute
                exact
                path="/orders"
                component={Orders}
                loggedIn={loggedIn}
              />
              <PrivateRoute
                exact
                path="/orders/:orders_id"
                component={Order}
                loggedIn={loggedIn}
              />
              <PublicRoute
                exact
                path="/login"
                component={Login}
                loggedIn={loggedIn}
              />
              <PublicRoute
                exact
                path="/logout"
                component={Logout}
                loggedIn={loggedIn}
              />
              <PublicRoute
                exact
                path="/user/forgot-password"
                component={ForgotPassword}
                loggedIn={loggedIn}
              />
              <PublicRoute
                exact
                path="/user/signup"
                component={Register}
                loggedIn={loggedIn}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </main>
          <Footer />
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  alert: state.alert,
  loggedIn: state.auth.loggedIn,
});

const actionCreators = (dispatch) => ({
  clearAlerts: alertsActions.clear,
  authVerify: authActions.verify,
  dispatch,
});

export default connect(mapStateToProps, actionCreators)(App);
