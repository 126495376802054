/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { connect } from "react-redux";

import { Translate } from "react-redux-i18nify";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

import SystemSelection from "./SystemSelection";
import Quoter from "./Quoter";
import { fetchSystem } from "../../redux/actions/system.actions";
import Loading from "../Layout/Loading";

import { history } from "../../helpers/history";

class Wizzard extends React.Component {
  constructor(props) {
    super(props);

    console.log("Iniciando Wizzard", props);

    this.state = {
      selectedSystem: null,
      cart: {},
      fields: {},
      selectedStep: 0,
      selectedStepItem: 0,
      showCart: false,
    };

    this.selectSystem = this.selectSystem.bind(this);
  }

  selectSystem(systems_id) {
    console.log("Sistema elegido", systems_id);
    history.push(`/s/${systems_id}`);
    // this.setState({
    //   selectedSystem: systems_id,
    //   cart: {},
    //   fields: this.initialFields(),
    // });
    // this.props.dispatch(fetchSystem(systems_id, this.stateSetter));
    // // Le avisamos al navegador que tenemos cambios sin guardar
    // window.onbeforeunload = (s) =>
    //   this.state.selectedSystem !== null ? '' : null;
  }

  render() {
    const { error, loading, system } = this.props;
    const {
      selectedSystem,
      selectedStep,
      selectedStepItem,
      cart,
      fields,
    } = this.state;

    // Si no se selecciono un sistema, entonces presentamos la seleccion
    if (!selectedSystem) {
      return (
        <Container>
          <h1>
            <Translate value="Choose your system" />
          </h1>
          <SystemSelection selectSystem={this.selectSystem} />
        </Container>
      );
    }

    // Ocurrio un error al cargar?
    if (error) {
      return (
        <Alert variant="danger">
          <Alert.Heading>Error!</Alert.Heading>
          <p>
            <Translate value={error.message} />
          </p>
        </Alert>
      );
    }

    // Estamos cargando?
    if (loading) {
      return <Loading />;
    }

    // Entonces mostramos el cotizador
    // return <Quoter system={system} />;
    return (
      <div>
        Carga finalizada
        <pre>{JSON.stringify(fields)}</pre>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  system: state.system.system,
  loading: state.system.loading,
  error: state.system.error,
});

export default connect(mapStateToProps)(Wizzard);
