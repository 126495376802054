/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import SelectMetadata from "./FormElements/SelectMetadata";
import SelectColorPrice from "./FormElements/SelectColorPrice";
import SystemQuantity from "./FormElements/SystemQuantity";
import Quantity from "./FormElements/Quantity";
import SelectSKU from "./FormElements/SelectSKU";
import SelectSKUQuantity from "./FormElements/SelectSKUQuantity";
import SelectSystemSwitcher from "./FormElements/SelectSystemSwitcher";

function FormElement(props) {
  const { element, state } = props;

  const evaluator = (func, vars) => {
    return new Function("v", "with (v) { return (" + func + ")}")(vars);
  };

  if (element.condition) {
    console.log("FormElement -- Aplying condition...", element.condition);
    if (!evaluator(element.condition, state)) {
      return null;
    }
  }

  switch (element.type) {
    case "SELECT_METADATA":
      return <SelectMetadata evaluator={evaluator} {...props} />;
    case "SELECT_COLOR_PRICE":
      return <SelectColorPrice evaluator={evaluator} {...props} />;
    case "SELECT_SKU":
      return <SelectSKU evaluator={evaluator} {...props} />;
    case "SELECT_SKU_QUANTITY":
      return <SelectSKUQuantity evaluator={evaluator} {...props} />;
    case "SELECT_SYSTEM_SWITCHER":
      return <SelectSystemSwitcher evaluator={evaluator} {...props} />;
    case "SYSTEM_QUANTITY":
      return <SystemQuantity evaluator={evaluator} {...props} />;
    case "QUANTITY":
      return <Quantity evaluator={evaluator} {...props} />;
    default:
      return null;
  }
}

export default FormElement;
