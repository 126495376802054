/**
 * This file is part of aluwind-cotizador-frontend
 */

import axios from "axios";
import config from "../config";
import { translate as t } from "react-i18nify";

export const userService = {
  login,
  logout,
  forgotPassword,
  register,
};

function login(username, password) {
  const bodyFormData = new FormData();

  bodyFormData.set("username", username);
  bodyFormData.set("password", password);

  return axios
    .post(`${config.API_ENDPOINT}/user/login`, bodyFormData, {
      withCredentials: true,
    })
    .then((response) => {
      const data = response.data;
      console.info("Successful login", data);
      sessionStorage.setItem("expires", data.expires);
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("user", JSON.stringify(data.user));
      // history.push('/');
    })
    .catch((error) => {
      console.error("Login error", error);
      if (error.response.status === 401) {
        return Promise.reject(t("Wrong username or password"));
      }

      return Promise.reject(t("ERROR_TRY_AGAIN"));
    });
}

function logout() {
  sessionStorage.removeItem("expires");
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
}

function forgotPassword(username) {
  const bodyFormData = new FormData();

  bodyFormData.set("username", username);

  return axios
    .post(`${config.API_ENDPOINT}/user/forgotPassword`, bodyFormData, {
      withCredentials: true,
    })
    .then((response) => {
      const data = response.data;
      console.info("ForgotPassword response", data);
    })
    .catch((error) => {
      console.error("ForgotPassword error", error);
      return Promise.reject(t("ERROR_TRY_AGAIN"));
    });
}

function register(
  username,
  company,
  firstname,
  lastname,
  email,
  phone,
  password,
) {
  const bodyFormData = new FormData();

  bodyFormData.set("username", username);
  bodyFormData.set("company", company);
  bodyFormData.set("firstname", firstname);
  bodyFormData.set("lastname", lastname);
  bodyFormData.set("email", email);
  bodyFormData.set("phone", phone);
  bodyFormData.set("password", password);

  return axios
    .post(`${config.API_ENDPOINT}/user/register`, bodyFormData, {
      withCredentials: true,
    })
    .then((response) => {
      const data = response.data;
      console.info("Successful register", data);
    })
    .catch((error) => {
      console.error("register error", error);
      console.error("response", error.response);
      if (error.response) {
        if (error.response.data.error && error.response.data.error.message) {
          return Promise.reject(t(error.response.data.error.message));
        }
      }
      return Promise.reject(t("ERROR_TRY_AGAIN"));
    });
}
