/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { connect } from "react-redux";

import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Alert from "react-bootstrap/Alert";

import { Translate } from "react-redux-i18nify";
import { Link } from "react-router-dom";
import { fetchSystems } from "../../redux/actions/systems.actions";
import { displayPrice } from "../../helpers/format";
import Loading from "../Layout/Loading";

class SystemSelection extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchSystems());
  }

  render() {
    const { error, loading, systems } = this.props;

    if (error) {
      return (
        <Alert variant="danger">
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error.message}</p>
        </Alert>
      );
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        {systems.map((category, index) => (
          <Card className="text-center border-0" key={index}>
            <Card.Header className="mt-3 mb-2">{category.category}</Card.Header>
            <CardDeck className="deck-m">
              {category.items.map((item, index) => (
                <Card
                  className="mb-3"
                  style={{ cursor: "pointer" }}
                  key={item.id}
                  onClick={() => this.props.selectSystem(item.id)}
                >
                  <img
                    className="card-img-top"
                    src={item.image}
                    alt={item.name}
                  />
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Body>
                  {item.price && (
                    <Card.Footer className="bg-none text-primary">
                      <small className="text-secondary">
                        <Translate value="Starting from" />
                      </small>
                      <br /> {item.price.currency}{" "}
                      {displayPrice(
                        item.price_ref
                          ? item.price_ref.value
                          : item.price.value,
                      )}{" "}
                      <small>+ IVA</small>
                    </Card.Footer>
                  )}
                </Card>
              ))}
            </CardDeck>
          </Card>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  systems: state.systems.items,
  loading: state.systems.loading,
  error: state.systems.error,
});

export default connect(mapStateToProps)(SystemSelection);
