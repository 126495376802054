/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";

import { Translate } from "react-redux-i18nify";
import Card from "react-bootstrap/Card";
import Media from "react-bootstrap/Media";

import FormElement from "./FormElement";

import { displayPrice } from "../../helpers/format";

function FormComponent(props) {
  const {
    component,
    state,
    stateSetter,
    selectSystem,
    system,
    setImageReference,
    primarySystemId,
  } = props;

  const evaluator = (func, vars) => {
    return new Function("v", "with (v) { return (" + func + ")}")(vars);
  };

  if (component.condition) {
    if (!evaluator(component.condition, state)) {
      return null;
    }
  }

  const imageReference = component.item
    ? component.item.images.large
    : component.system
    ? component.system.image_reference || component.system.image
    : null;

  return (
    <Card className="border-0 mb-2 pt-2 px-3 bg-light">
      <Card.Body className="p-0">
        {component.item && (
          <>
            <Media className="mb-3">
              <img
                width={130}
                height={130}
                className="mr-3"
                src={component.item.images.small}
                alt={component.item.name}
                onClick={() => {
                  imageReference && setImageReference(imageReference);
                }}
                style={imageReference && { cursor: "zoom-in" }}
              />
              <Media.Body>
                <h5 className="mt-2">{component.item.name}</h5>
                <p>{component.item.description}</p>
                <p>
                  <small>
                    <Translate value="Price" />: {component.item.price.currency}
                    {displayPrice(component.item.price.value)}
                  </small>
                </p>
              </Media.Body>
            </Media>
          </>
        )}
        {component.system && (
          <>
            <Media className="mb-3">
              <img
                width={130}
                height={130}
                className="mr-3"
                src={component.system.image}
                alt={component.system.name}
                onClick={() => {
                  imageReference && setImageReference(imageReference);
                }}
                style={imageReference && { cursor: "zoom-in" }}
              />
              <Media.Body>
                <h5 className="mt-2">{component.system.name}</h5>
                <p>
                  {component.system.description}.{" "}
                  {component.system.visible_length && (
                    <small>
                      (<Translate value="Length" />: {component.system.length}{" "}
                      mts.)
                    </small>
                  )}
                </p>
                {component.comment && (
                  <p>
                    <small>{component.comment}</small>
                  </p>
                )}
                {component.system.price.visible && (
                  <p>
                    <small>
                      <Translate value="Price" />:{" "}
                      {component.system.price.currency}
                      {displayPrice(component.system.price.value)}
                    </small>
                  </p>
                )}
              </Media.Body>
            </Media>
          </>
        )}
        {component.elements.map((element, index) => (
          <FormElement
            component={component}
            element={element}
            evaluator={evaluator}
            key={index}
            state={state}
            stateSetter={stateSetter}
            selectSystem={selectSystem}
            system={system}
            setImageReference={setImageReference}
            imageReference={imageReference}
            primarySystemId={primarySystemId}
          />
        ))}
      </Card.Body>
    </Card>
  );
}

export default FormComponent;
