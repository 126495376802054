export const applyDiscount = (system, quantity) => {
  console.log("applyDiscount", system);
  const priceKey = "price";
  const meters = system.length * quantity;
  let price = system[priceKey].value;
  console.log("price_base", price);
  console.log("meters", meters);
  if (system.type === "SYSTEM" && system.discounts_enabled === "Y") {
    if (meters >= 100) {
      console.log("Descuento > 100");
      return price * 0.9;
    }
    if (meters >= 30) {
      console.log("Descuento > 30");
      return price * 0.95;
    }
  }
  console.log("Sin descuento");
  return price;
};
