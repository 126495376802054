/**
 * This file is part of aluwind-cotizador-frontend
 */

import {
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_CLEAR,
  ALERT_CLEAR_ALL,
} from "../actions/alerts.actions";

function alerts(state = [], action) {
  switch (action.type) {
    case ALERT_SUCCESS:
      return [
        ...state,
        {
          variant: "success",
          message: action.message,
        },
      ];
    case ALERT_ERROR:
      return [
        ...state,
        {
          variant: "danger",
          message: action.message,
        },
      ];
    case ALERT_CLEAR:
      return [
        ...state.alerts.slice(0, action.id),
        ...state.alerts.slice(action.id + 1),
      ];
    case ALERT_CLEAR_ALL:
      return [];
    default:
      return state;
  }
}

export default alerts;
