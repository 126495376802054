/**
 * This file is part of aluwind-cotizador-frontend
 */

import axios from "axios";
import config from "../../config";
import { history } from "../../helpers/history";

export const AUTH_VERIFY_BEGIN = "AUTH_VERIFY_BEGIN";
export const AUTH_VERIFY_SUCCESS = "AUTH_VERIFY_SUCCESS";
export const AUTH_VERIFY_FAILURE = "AUTH_VERIFY_FAILURE";
export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const authActions = {
  verify,
  login,
  logout,
};

function verify() {
  return (dispatch) => {
    dispatch(request());

    const expires = sessionStorage.getItem("expires");
    const token = sessionStorage.getItem("token");
    const user = JSON.parse(sessionStorage.getItem("user"));

    if (expires && token && user) {
      return dispatch(success({ user, token, expires }));
    }

    return dispatch(failure());
  };

  function request(auth) {
    return { type: AUTH_VERIFY_BEGIN, auth };
  }
  function success(auth) {
    return { type: AUTH_VERIFY_SUCCESS, auth };
  }
  function failure(error) {
    return { type: AUTH_VERIFY_FAILURE, error };
  }
}

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));
    const bodyFormData = new FormData();

    bodyFormData.set("username", username);
    bodyFormData.set("password", password);

    return axios
      .post(`${config.API_ENDPOINT}/user/login`, bodyFormData, {
        withCredentials: true,
      })
      .then((response) => {
        const data = response.data;
        sessionStorage.setItem("expires", data.expires);
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("user", JSON.stringify(data.user));
        dispatch(
          success({
            user: data.user,
            token: data.token,
            expires: data.expires,
          }),
        );
        history.push("/");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            return dispatch(failure("Wrong username or password"));
          }

          if (error.response.status === 403) {
            return dispatch(failure("Disabled user"));
          }
        }

        dispatch(failure("Something went wrong. Please try again later"));
      });
  };

  function request(auth) {
    return { type: AUTH_LOGIN_REQUEST, auth };
  }
  function success(auth) {
    return { type: AUTH_LOGIN_SUCCESS, auth };
  }
  function failure(error) {
    return { type: AUTH_LOGIN_FAILURE, error };
  }
}

function logout() {
  sessionStorage.removeItem("expires");
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  history.push("/login");
  return { type: AUTH_LOGOUT };
}
