const en = {
  loading: "Loading...",
  PENDING: "Pending",
  PENDING_AUTHORIZATION: "Pending authorization",
  CANCELLED: "Cancelled",
  REQUESTED: "Pending authorization",
  ERROR_TRY_AGAIN: "Something went wrong. Please try again later",
};

export default en;
