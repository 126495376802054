/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { connect } from "react-redux";

function Footer(props) {
  const { user } = props;
  return (
    <footer className="footer mt-auto py-3 d-print-none">
      <div className="container text-center">
        <small>
          &copy; Copyright 2020 Aluwind S.A - by{" "}
          <a
            href="https://www.propositiva.com.ar/?utm_source=aluwind-cotizador&utm_medium=website&utm_campaign=footer"
            style={{ color: "#00A6D6" }}
          >
            <img
              src={"/images/propositiva-" + (user ? "white" : "dark") + ".png"}
              height="20"
              alt="Propositiva - Consultora IT"
            />
          </a>
        </small>
      </div>
    </footer>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Footer);
