/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { connect } from "react-redux";
import { authActions } from "../../redux/actions/auth.actions";

class Logout extends React.Component {
  componentWillMount() {
    this.props.dispatch(authActions.logout());
  }

  render() {
    return null;
  }
}

export default connect()(Logout);
