/**
 * This file is part of aluwind-cotizador-frontend
 */

import React from "react";
import { connect } from "react-redux";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Col";

import LoginHeader from "./LoginHeader";
import { registrationActions } from "../../redux/actions/registration.actions";

import { LinkContainer } from "react-router-bootstrap";
import { I18n, Translate } from "react-redux-i18nify";
import { translate as t } from "react-i18nify";

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      company: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      password: "",
      password2: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleValidation(e) {
    const { password, password2 } = this.state;

    const elm = document.getElementById("formPassword2");

    if (password2 !== password) {
      elm.setCustomValidity(t("Passwords don't match"));

      return false;
    }

    elm.setCustomValidity("");

    return true;
  }

  handleSubmit(e) {
    console.log("HandleSubmit");
    e.preventDefault();

    const {
      username,
      company,
      firstname,
      lastname,
      email,
      phone,
      password,
      password2,
    } = this.state;
    if (
      username &&
      company &&
      firstname &&
      lastname &&
      email &&
      phone &&
      password &&
      password2
    ) {
      this.props.dispatch(
        this.props.register(
          username,
          company,
          firstname,
          lastname,
          email,
          phone,
          password,
          password2,
        ),
      );
    }
  }

  render() {
    const { loading, error } = this.props;
    const {
      username,
      company,
      firstname,
      lastname,
      email,
      phone,
      password,
      password2,
    } = this.state;
    return (
      <Container fluid="md">
        <Row className="justify-content-md-center mt-md-5">
          <Col xs lg="4">
            <LoginHeader />
            <Card bg="light">
              <Card.Body>
                <Card.Title className="text-center">
                  <Translate value="Sign up" />
                </Card.Title>
                <Card>
                  <Card.Body>
                    <Form onSubmit={this.handleSubmit} autoComplete="off">
                      <Form.Group as={Row} controlId="formUsername">
                        <Form.Label column sm="3">
                          <Translate value="CUIT" />
                        </Form.Label>
                        <Col sm="9">
                          <I18n
                            render={() => (
                              <Form.Control
                                type="text"
                                name="username"
                                autoComplete="username"
                                placeholder={t("CUIT")}
                                aria-label={t("CUIT")}
                                value={username}
                                onChange={this.handleChange}
                                pattern=".{11}"
                                title="El CUIT debe poseer 11 digitos"
                                required
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formCompany">
                        <Form.Label column sm="3">
                          <Translate value="Company name" />
                        </Form.Label>
                        <Col sm="9">
                          <I18n
                            render={() => (
                              <Form.Control
                                type="text"
                                name="company"
                                autoComplete="company"
                                placeholder={t("Company name")}
                                aria-label={t("Company name")}
                                value={company}
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formFullname">
                        <Form.Label column sm="3">
                          <Translate value="Name" />
                        </Form.Label>
                        <Col sm="4">
                          <I18n
                            render={() => (
                              <Form.Control
                                type="text"
                                name="firstname"
                                placeholder={t("First name")}
                                aria-label={t("First name")}
                                value={firstname}
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          />
                        </Col>
                        <Col sm="5">
                          <I18n
                            render={() => (
                              <Form.Control
                                type="text"
                                name="lastname"
                                placeholder={t("Last name")}
                                aria-label={t("Last name")}
                                value={lastname}
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="fromEmail">
                        <Form.Label column sm="3">
                          <Translate value="Email" />
                        </Form.Label>
                        <Col sm="9">
                          <I18n
                            render={() => (
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder={t("Email")}
                                aria-label={t("Email")}
                                value={email}
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPhone">
                        <Form.Label column sm="3">
                          <Translate value="Contact phone" />
                        </Form.Label>
                        <Col sm="9">
                          <I18n
                            render={() => (
                              <Form.Control
                                type="text"
                                name="phone"
                                autoComplete="phone"
                                placeholder={t("Contact phone")}
                                aria-label={t("Contact phone")}
                                value={phone}
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPassword">
                        <Form.Label column sm="3">
                          <Translate value="Password" />
                        </Form.Label>
                        <Col sm="9">
                          <I18n
                            render={() => (
                              <Form.Control
                                type="password"
                                name="password"
                                autoComplete="new-password"
                                placeholder={t("Password")}
                                aria-label={t("Password")}
                                value={password}
                                onChange={this.handleChange}
                                pattern=".{8,}"
                                title="La contraseña debe poseer al menos 8 caracteres"
                                required
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPassword2">
                        <Form.Label column sm="3">
                          <Translate value="Confirm password" />
                        </Form.Label>
                        <Col sm="9">
                          <I18n
                            render={() => (
                              <Form.Control
                                type="password"
                                name="password2"
                                autoComplete="new-password"
                                placeholder={t("Confirm password")}
                                aria-label={t("Confirm password")}
                                value={password2}
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>
                      {/*error && (
                        <Form.Text className="text-danger mb-3">
                          {error}
                        </Form.Text>
                      )*/}
                      <Button
                        type="submit"
                        className="float-right"
                        disabled={loading}
                        onClick={this.handleValidation}
                      >
                        <Translate value={loading ? "loading" : "Sign up"} />
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
                <LinkContainer to="/user/forgot-password">
                  <Card.Link href="#" className="float-left mt-2">
                    <Translate value="I forgot my password" />
                  </Card.Link>
                </LinkContainer>
                <LinkContainer to="/login">
                  <Card.Link href="#" className="float-right mt-2">
                    <Translate value="Login" />
                  </Card.Link>
                </LinkContainer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.registration.loading,
  error: state.registration.error,
});

const actionCreators = (dispatch) => ({
  register: registrationActions.register,
  dispatch,
});

export default connect(mapStateToProps, actionCreators)(Register);
